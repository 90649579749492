





.one{
display: none;
}
.two{
  display: block;
  }









/* ///////////////GLOBAL///////////////// */

* {
  font-family: "Amazon Ember", sans-serif;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-30 {
  gap: 30px;
}

#d-f {
  display: flex;
}

#d-g {
  display: grid;
}

#a-c {
  display: flex;
  align-items: center;
}

#j-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

#between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#wrap {
  display: flex;
  flex-wrap: wrap;
}

#wrap-ce {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#col {
  display: flex;
  flex-direction: column;
}

#abs {
  position: absolute;
}

#col-ce {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#wrap {
  display: flex;
  flex-wrap: wrap;
}

#wrap-ce {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#t-a-c {
  text-align: center;
}

#c-p {
  cursor: pointer;
}

#red {
  color: red;
}

#my-color {
  color: white;
  background-color: #232f3e;
}

#center {
  display: flex;
  align-items: center;
  justify-content: center;
}

#text-center {
  text-align: center;
}

#no-a {
  color: black;
  text-decoration: none;
}
.no-a{
  color: black;
  text-decoration: none;
}

.c-p {
  cursor: pointer;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}

.blue {
  color: #007185;
}

.col {
  display: flex;
  flex-direction: column;
}

.align {
  display: flex;
  align-items: center;
}

.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.m-b {
  margin-bottom: 30px;
}

.line {
  border-top: 1px solid rgb(158, 158, 158);
  margin-top: 2w0px;
}

.render{
  margin: 30px;
  padding: 20px;
  border: 3px solid red;
}

.color-global {
  width: 60px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid black;
  cursor: pointer;
}

.whereAmI {
  display: flex;
  align-items: center;
}

.whereAmI a {
  text-decoration: none;
  color: grey;
  font-family: Georgia, "Times New Roman", Times, serif;
}

/* header  HEADER HEAD*/

.explore-categories img {
  width: 13%;
  padding: 1%;
}

.header-icons {
  width: 43%;
}

/* body */
.body-banner {
  width: 100%;
}

.body-header-image {
  width: 100%;
}

body p,
a {
  font-size: 14px;
}

.body-all-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #e3e6e6;
  margin-top: -150px;
}

.categories-box {
  /* width: 340px; */
  width: 240px;
  /* height: 400px; */
  height: 300px;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: white;
  /* margin: 10px; */
  margin: 5px;
}

.categories-box a {
  text-decoration: none;
}

.categories-box h5 {
  font-weight: bold;
}

.categories-box img {
  width: 220px;
  height: 220px;
  object-fit: cover;
  z-index: 1;
  pointer-events: none;
}

.body-main-banner {
  width: 100%;
}

/* footer//////// */

.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-around;
  background-color: #232f3e;
  color: white;
}
.footer a {
  text-decoration: none;
  color: white;
  font-size: 12px;
  margin-top: -4px;
}

.footer-links {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* SIGNLE-PRODUCT/////////// */

.single-product-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.single-product-login-and-all-the-information {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.single {
  display: flex;
  justify-content: space-around;
}

 .title-img{
  max-height: 400px;
  border-radius: 10px;
} 

.single-name{
  max-width: 400px;
}

.single-images {
  display: flex;
  text-align: center;
  justify-content: space-around;
  direction: rtl;
}

.others-img-div{
  width: 100%;
  display: grid;
  justify-content: space-around;
}

.others-img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border: 2px solid skyblue;
  margin: 10px;
}

.single-product-text-align {
  text-align: justify;
}

.single-product-color {
  width: 30px;
  height: 30px;
  border: 1px solid black;
  /* border: 1px solid #007185; */
  cursor: pointer;
  border-radius: 50%;
}

.add-to-cart-button {
  padding: 5px 10px;
  border-radius: 7px;
  border: 1px solid green;
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personal-images {
  width: 150px;
  height: 150px;
  padding: 5px;
  border-radius: 15px;
}

.personal-images-div{
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.cart-products {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  text-decoration: none;
}

.cart-map {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: space-around;
}

.compare-products {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  text-decoration: none;
}

.cart-a-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
  min-height: 500px;
}

.cart-button {
  background-color: rgb(255, 221, 0);
  padding: 8px 12px;
  border-radius: 20px;
}

/* login/////////// */

/* google oath login  */
.signin {
  display: grid;
  align-items: center;
  justify-content: center;
}

.signin2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signin input,
.signin button {
  padding: 8px 25px;
  border-radius: 15px;
  margin-top: 10px;
}

.signin-by-email-and-password {
  display: grid;
  align-items: center;
  justify-content: center;
  border-right: 1px solid grey;
  padding-right: 20px;
}

.google {
  background-color: white;
  padding-left: 20px;
}

.googles {
  width: 260px;
  display: flex;
align-items: center;
gap: 20px;
  border-radius: 40px;
  padding: 8px 25px;
  margin-top: 10px;
  font-size: 13px;
  border: 1px solid grey;
}

.googles img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}


.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
/* //////////google oath ends  */

.login-signOut {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  width: 300px;
  height: 300px;
  border: 1px solid grey;
  display: grid;
  padding: 20px;
  margin: 20px;
}

.login button {
  background-color: rgb(255, 242, 0);
  border-radius: 10px;
  border: none;
  height: 35px;
  margin-top: 20px;
  padding: 5px;
}

.login button :hover {
  background-color: skyblue;
  width: 100%;
  height: 100%;
}

/* products///////// */

.products-box {
  width: 300px;
  height: 500px;
  /* background-color: #E3E6E6; */
  background-color: white;
  margin: 3px;
  cursor: pointer;
  border: 1px solid grey;
  margin-top: 10px;
  padding: 10px;
}

.search {
  display: flex;
}

.products-box-search {
  display: flex;
  border: 1px solid grey;
}

.products-box-search img {
  width: 200px;
  min-width: 200px;
  padding: 5px;
  object-fit: contain;
}

.products-box img {
  width: 100%;
  height: 60%;
  object-fit: contain;
  background-color: white;
  margin-bottom: 10px;
}

.products-box:hover {
  cursor: pointer;
  filter: brightness(90%);
  transition: all 0.5s ease-out;
}

.products-count-main {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid green;
  text-align: center;
  margin: 40px 1px;
  cursor: pointer;
  padding: 0 40px;
}
.products-count {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  text-align: center;
  /* margin-top: 40px; */
  margin: 40px 1px;
  cursor: pointer;
}

.best-seller-orange {
  width: 100px;
  border-right: 10px solid transparent;
  border-top: 27px solid rgb(244, 130, 0);
  border-radius: 5px 5px 0 0;
  margin-bottom: -10px;
  margin-left: 3px;
}

.best-seller-orange-none {
  width: 100px;
  border-left: 10px solid transparent;
  border-top: 27px solid transparent;
  border-radius: 5px 5px 0 0;
  margin-bottom: -10px;
  margin-right: 10px;
}

.best-seller-orange-text {
  margin-top: -24px;
  margin-left: 10px;
  color: white;
}

.hide-filter {
  display: none;
}
.show-filter {
  display: block;
}

/* leftmenu///////// */
.left-menu-products {
  border-left: 1px solid rgba(158, 158, 158, 50%);
  padding-top: 10px;
  background-color: rgb(250, 250, 250);
}

.left-menu-products a {
  text-decoration: none;
  color: #111111;
  padding-left: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.payment {
  display: grid;
  width: 300px;
  height: 44vh;
}

.payment button {
  background-color: rgb(0, 175, 0);
}

.loading {
  height: 10px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgb(227, 227, 227) 0%,
    rgb(180, 178, 178) 25%,
    rgb(85, 84, 84) 50%,
    rgb(39, 39, 39) 100%
  );
  background-size: 200% 100%;
  animation: ani 1s linear infinite;
}

@keyframes ani {
  0% {
    background-position: 10%;
  }

  50% {
    background-position: 150%;
  }

  100% {
    background-position: 450%;
  }
}


  .loading2::after {
      display: inline-block;
      animation: dotty steps(1,end) 1s infinite;
      content: '';
    }
    /* I just removed padding to be more beautiful you can delete below code  */
    .loading2{
      padding: 0;
      margin: 0;
      width: 25px;
      font-size: 25px;
    }
    
    @keyframes dotty {
        0%   { content: ''; }
        25%  { content: '.'; }
        50%  { content: '..'; }
        75%  { content: '...'; }
        100% { content: ''; }
    }


  

/* 1000 */
@media screen and (max-width: 1000px) {
  .header-icons {
    width: 100%;
  }

  .hide-filter {
    display: block;
  }
  .show-filter {
    display: none;
  }

  .search {
    display: grid;
  }

  .single-product-login-and-all-the-information {
    margin-top: 100px;
  }

  .product-card-body {
    justify-content: center;
  }
}


/* Header///////   */

.input-groups {
  display: flex;
  height: 40px;
}

.blackModal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 40%);
  transition: all 0.5s ease-out;
  z-index: 10;
  position: absolute;
}

.searchModal {
  width: 380px;
  height: fit-content;
  top: 55px;
  display: grid;
  background-color: white;
  color: black;
  transition: all 0.5s ease-out;
  z-index: 11;
  position: absolute;
}

.searchModalHidden {
  display: none;
}

.cart-img {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

.header-a-icons {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 6px;
  gap: 6px;
}

.eesy-purchase {
  margin-top: -15px;
  font-size: 13px;
  color: lightgreen;
}

.header-items a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
}

/* Home////////// */

.home-short{
  display: none;
}

.four-nice-img{
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.services {
  display: flex;
  justify-content: space-around;
}
.services img {
  width: 50px;
}

.home222 {
  display: flex;
}
.home222 a {
  text-decoration: none;
  color: black;
  /* padding: 10px; */
  padding: 6px;
  border: 1px solid black;
  border-radius: 20px;
  margin: 0 5px;
  font-size: 10px;
}

.start-now {
  padding: 10px;
  margin: 20px 0;
  background-color: rgb(239, 239, 239);
}
.start-now-butt {
  background-color: black;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}

.round-img {
  display: flex;
  justify-content: space-around;
}

.round-img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.brands-img {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.home-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.special {
  padding: 20px 0;
  background-color: palevioletred;
}

.special img {
  width: 300px;
}

.gray {
  background-color: rgb(250, 250, 250);
  
}

.button {
  background-color: #232f3e;
  color: white;
  padding: 13px 33px;
  border-radius: 15px;
  text-decoration: none;
  font-family: arial;
}

.button:hover {
  background-color: var(--color-light-orange);
  /* color: black; */
}

.four-items {
  width: 49%;
}

/* ///////////////////////////////////////////ProductCart */
.home-products {
  width: 15%;
  padding: 10px;
}
.home-products img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

/* /////////////////// I THINK IT'S SINGLE PRODUCT  */
.review {
  box-shadow: 0 0 5px var(--color-shadow);
}

.small-images img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid black;
}

.img-zoom {
  z-index: 1000;
}

.header-short {
  display: none;
}

.short-line1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 10px; */
}


  .special-img{
    width: 80%;
  }

  .empty-img{
    width: 350px;
  }

/* phone cellphone android small */
@media screen and (max-width: 650px) {

  .searchModal {
    width: 100%;
    height: fit-content;
    top: 80px;
    display: grid;
    background-color: white;
    color: black;
    transition: all 0.5s ease-out;
    z-index: 11;
    position: absolute;
  }


  .one{
    display: block;
    }
    .two{
      display: none;
      }



  .empty-img{
    width: 150px;
  }

  .empty{
    display: grid;
  }

  .empty h4{
    font-size: 15px;
  }

  .empty span {
    width: fit-content;
    background-color: rgb(255, 221, 0);
    padding: 2px 4px;
    font-size: 10px;
  }

  .special-img{
    width: 95%;
  }

  .signin-by-email-and-password {
    border-right: none;
    padding: 10px 0;
    border-bottom: 1px solid grey;
  }

  .products-box-search img {
  width: 140px;
  min-width: 140px;
  object-fit: contain;
}

 .signin2{
  display: grid;
 } 
 
  .single-images{
    width: 100%;
    display: grid;
    text-align: center;
  }

  .title-img{
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-radius: 0;
  }

.others-img-div{
  width: 100%;
  display: flex;
}

.others-img {
  width: 55px;
  width: 100%;
  border-radius: 5px;
  padding: 3px;
}


  .home111 {
    justify-content: center;
  }

  .home-products {
    width: 49%;
    /* padding: 10px; */
    padding: 0 5px;
    overflow: hidden;
  }

  .home-short{
    display: block;
  }

  .explore-categories img {
    width: 33%;
  }

  .body-all-boxes {
    margin-top: -50px;
  }

  .services img {
    width: 30px;
  }
  .services p {
    font-size: 9px;
  }

  .single {
    display: grid;
  }


  .search-div-width {
    width: 100%;
  }

  .search-form-width {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .search-input-width {
    width: 90%;
  }

  .header-long {
    display: none;
  }

  .header-short {
    display: grid;
  }

  .cart-short {
    top: 15px;
    width: 10px;
    height: 15px;
    font-size: small;
    color: red;
    background-color: white;
    margin-left: 2px;
    border-radius: 50%;
    position: absolute;
  }

  .cart-map {
    display: grid;
  }

  .email-signup {
    display: grid;
  }

  .body-banner {
    display: none;
    /* width: 100%; */
    /* height: 160px; */
    /* object-fit: cover; */
  }

  .personal-images {
    width: 80px;
    height: 80px;
  }

  .home-images {
    display: flex;
    flex-direction: column;
  }

  .product-card-body {
    justify-content: center;
  }
}
